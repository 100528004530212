import { Flex, Text } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { CurrencyHelpersWithoutBorderConversion } from 'helpers_composable/currencyHelpersWithoutBorderConversion'
import { useAtgUser } from 'frontastic/contexts'
import { HorizontalProductCardProps } from './types'
import { cartOptionValues } from './utils'

export const PriceDetails = (props: HorizontalProductCardProps) => {
  // /* need to come back and check discount working when we get a discount price ticket */
  const { formatMessage } = useFormat({ name: 'common' })
  const {
    columns,
    size,
    isDiscounted,
    totalDiscountedPrice,
    totalPrice,
    totalDiffBtwPriceAndDiscountedPrice,
    hemmingPriceInfo,
    hemmingPrice,
    totalCentPrice,
    itemFinalDisplayPrice,
    promoDiscounts,
  } = props

  const { itemPriceOptions } = cartOptionValues({ columns, size })
  const { userSessionData } = useAtgUser()

  const hemmingPriceInfoArray = hemmingPriceInfo ? hemmingPriceInfo.split(',').filter((item) => item.trim() !== '') : []
  const hemmingInfo = hemmingPriceInfoArray.length
    ? hemmingPriceInfoArray?.[0] + ', ' + hemmingPriceInfoArray?.[1] + ' : '
    : null
  const hemmingCovertedPrice = CurrencyHelpersWithoutBorderConversion.formatForCurrency(
    Math.round(hemmingPrice * 100),
    userSessionData.dxlCurrency,
  )
  const total = totalCentPrice + Math.round(hemmingPrice * 100)
  const hemmingTotalPrice = CurrencyHelpersWithoutBorderConversion.formatForCurrency(total, userSessionData.dxlCurrency)

  const ReturnPriceIfInCartOrCartDrawer = () => {
    if (itemPriceOptions.type === 'column') {
      // cart page
      return (
        <>
          <Flex>
            <Text mr={1} fontSize={'14px'} textStyle={'figma-strikethrough-75'}>
              {totalPrice}
            </Text>
          </Flex>
          <Flex>
            <Text
              fontSize={'14px'}
              textStyle={'body-50'}
              color={totalDiscountedPrice ? 'danger.600' : undefined}
              marginLeft="2px"
            >
              {CurrencyHelpersWithoutBorderConversion.formatForCurrency(
                itemFinalDisplayPrice,
                userSessionData.dxlCurrency,
              )}
            </Text>
          </Flex>
        </>
      )
    } else {
      return (
        <Flex>
          <Text
            tabIndex={0}
            aria-label={formatMessage({
              id: 'product.price.sale.originalPrice',
              values: {
                amount: hemmingTotalPrice ? hemmingTotalPrice : totalPrice,
              },
            })}
            mr={1}
            fontSize={'14px'}
            textStyle={'figma-strikethrough-75'}
          >
            {hemmingTotalPrice ? hemmingTotalPrice : totalPrice}
          </Text>
          {hemmingInfo && hemmingCovertedPrice && (
            <Text>
              {hemmingInfo} {hemmingCovertedPrice}
            </Text>
          )}
          <Text
            fontSize={'14px'}
            textStyle={'body-50'}
            color={totalDiscountedPrice ? 'danger.600' : undefined}
            marginLeft="2px"
            tabIndex={0}
            aria-label={formatMessage({
              id: 'product.price.sale.finalPrice',
              values: {
                amount: CurrencyHelpersWithoutBorderConversion.formatForCurrency(
                  itemFinalDisplayPrice,
                  userSessionData.dxlCurrency,
                ),
              },
            })}
          >
            {CurrencyHelpersWithoutBorderConversion.formatForCurrency(
              itemFinalDisplayPrice,
              userSessionData.dxlCurrency,
            )}
          </Text>
        </Flex>
      )
    }
  }

  return (
    <>
      <Flex flexDirection="column" marginTop="7px">
        {isDiscounted ? (
          <ReturnPriceIfInCartOrCartDrawer />
        ) : (
          <>
            <Text tabIndex={0} fontSize={'14px'} textStyle={'body-50'}>
              {CurrencyHelpersWithoutBorderConversion.formatForCurrency(
                itemFinalDisplayPrice,
                userSessionData.dxlCurrency,
              )}
            </Text>
            {hemmingInfo && hemmingCovertedPrice && (
              <Text>
                {hemmingInfo} {hemmingCovertedPrice}
              </Text>
            )}
          </>
        )}
      </Flex>

      {/* display this only in cart drawer */}
      {promoDiscounts?.discountAmount > 0 && itemPriceOptions.type !== 'column' && (
        <>
          <Text tabIndex={0} fontSize={'12px'} textStyle={'body-50'} color={'danger.600'} fontWeight={400}>
            {formatMessage({
              id: 'product.price.sale.youSaved',
              values: {
                amount: CurrencyHelpersWithoutBorderConversion.formatForCurrency(
                  promoDiscounts.discountAmount,
                  userSessionData.dxlCurrency,
                ),
              },
            })}
          </Text>
        </>
      )}
    </>
  )
}
