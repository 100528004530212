import { Text, Alert, Icon, HStack } from '@chakra-ui/react'
import { core } from 'composable/chakra/figma-tokens'
import { useFormat } from 'helpers/hooks/useFormat'
import { GoAlertFill } from 'react-icons/go'
import { HorizontalProductCardProps } from './types'
import { cartlabels } from './utils'
import { DONATION_SKU } from '../general/constants'

export const OutOfStockAlertMessage = (props: HorizontalProductCardProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const labels = cartlabels(formatMessage)

  const { quantity, variant, onRemove, onChangeQuantity, isLoading } = props
  const notEnoughStock = formatMessage({
    id: 'cart.item.notEnoughStock',
    values: { quantity: variant?.availableQuantity },
  })

  const isNotEnoughInStock =
    variant.sku != DONATION_SKU && variant?.availableQuantity > 0 && variant?.availableQuantity < quantity
      ? variant?.availableQuantity
      : null

  const applyQuantity = (quantity: number) => async () => {
    onChangeQuantity(quantity)
  }

  return (
    <Alert
      status={isNotEnoughInStock ? 'warning' : 'error'}
      rounded="md"
      textStyle="body-75"
      borderLeft="4px solid"
      borderLeftColor={isNotEnoughInStock ? core.warning['warning-500'] : core.danger['danger-600']}
      bgColor={isNotEnoughInStock ? core.warning['warning-100'] : core.danger['danger-100']}
      mt={4}
    >
      {isNotEnoughInStock ? (
        <HStack gap={3}>
          <Icon as={GoAlertFill} boxSize={6} color={core.warning['warning-500']} />
          <Text>
            {notEnoughStock}
            <Text
              as="button"
              textDecoration="underline"
              onClick={isLoading ? undefined : applyQuantity(isNotEnoughInStock)}
              cursor={isLoading ? 'initial' : 'pointer'}
              aria-label={labels.removeFromBag}
              color={isLoading ? 'text-muted' : undefined}
            >
              {labels.apply}
            </Text>
          </Text>
        </HStack>
      ) : (
        <HStack gap={3}>
          <Icon as={GoAlertFill} boxSize={6} color={core.danger['danger-600']} />
          <Text>
            {labels.outOfStock}
            {'  '}
            <Text
              as="button"
              textDecoration="underline"
              onClick={isLoading ? undefined : onRemove}
              cursor={isLoading ? 'initial' : 'pointer'}
              aria-label={labels.removeFromBag}
              color={isLoading ? 'text-muted' : undefined}
            >
              {labels.removeFromBag}
            </Text>
          </Text>
        </HStack>
      )}
    </Alert>
  )
}
