import { Money } from '@Types/product/Money'
import { Log } from './errorLogger'
import { StringHelpers } from './stringHelpers'

export class CurrencyHelpersWithoutBorderConversion {
  private static formatNumberForCurrency = function (costInCents: number, currency = 'USD') {
    return CurrencyHelpersWithoutBorderConversion.formatMoneyCurrency(
      {
        centAmount: costInCents,
        currencyCode: currency,
        fractionDigits: 2,
      },
      currency,
    )
  }

  private static formatStringForCurrency = function (costInCents: string, currency = 'USD') {
    if (!StringHelpers.isNumeric(costInCents)) {
      Log.error(`Value (${costInCents}) passed for currency formatting cannot be parsed to a number`)
      return ''
    }
    return CurrencyHelpersWithoutBorderConversion.formatNumberForCurrency(parseInt(costInCents, 10), currency)
  }

  private static formatMoneyCurrency = function (price: Money, currency = 'USD') {
    let locale = 'en-US'
    return Intl.NumberFormat(locale, {
      style: 'currency',
      currency: price?.currencyCode ?? currency,
      currencyDisplay: currency === 'USD' ? 'symbol' : 'code',
    })
      .format((price?.centAmount ?? 0) / Math.pow(10, price?.fractionDigits ?? 2))
      .replace(/(\w{3})\s+([0-9.,]+)/, '$1$2')
  }

  /**
   * formatForCurrency formats a string of number into a cost representation. If
   * passing a string it must be numeric only.
   */
  static formatForCurrency = (costInCents: string | number | Money, currency = 'USD') =>
    typeof costInCents === 'string'
      ? CurrencyHelpersWithoutBorderConversion.formatStringForCurrency(costInCents, currency)
      : typeof costInCents === 'number'
        ? CurrencyHelpersWithoutBorderConversion.formatNumberForCurrency(costInCents, currency)
        : CurrencyHelpersWithoutBorderConversion.formatMoneyCurrency(costInCents, currency)

  static addCurrency: (value1?: Money, value2?: Money) => Money = (value1?: Money, value2?: Money) => {
    if (!value1) value1 = { centAmount: 0 }
    if (!value2) value2 = { centAmount: 0 }

    if (value1.fractionDigits !== value2.fractionDigits && value1.fractionDigits && value2.fractionDigits) {
      Log.warn(
        `Money with different fraction codes passed to addCurrency, value returned will be innacurate. ` +
          `Value 1: ${value1.fractionDigits}, value 2: ${value2.fractionDigits}`,
      )
    }
    if (value1.currencyCode !== value2.currencyCode && value1.currencyCode && value2.currencyCode) {
      Log.warn(
        `Money with different currency codes passed to addCurrency, value returned will be innacurate. ` +
          `Value 1: ${value1.currencyCode}, value 2: ${value2.currencyCode}`,
      )
    }

    return {
      fractionDigits: value1.fractionDigits || value2.fractionDigits,
      centAmount: value1.centAmount + value2.centAmount,
      currencyCode: value1.currencyCode || value2.currencyCode,
    }
  }

  static subtractCurrency: (value1: Money, value2: Money) => Money = (value1: Money, value2: Money) => {
    if (value1.fractionDigits !== value2.fractionDigits) {
      Log.warn(
        `Money with different fraction codes passed to addCurrency, value returned will be innacurate. ` +
          `Value 1: ${value1.fractionDigits}, value 2: ${value2.fractionDigits}`,
      )
    }
    if (value1.currencyCode !== value2.currencyCode) {
      Log.warn(
        `Money with different currency codes passed to addCurrency, value returned will be innacurate. ` +
          `Value 1: ${value1.currencyCode}, value 2: ${value2.currencyCode}`,
      )
    }
    return {
      fractionDigits: value1.fractionDigits || value2.fractionDigits,
      centAmount: value1.centAmount - value2.centAmount,
      currencyCode: value1.currencyCode || value2.currencyCode,
    }
  }

  static multiplyCurrency: (value: Money, numberOfItems: number) => Money = (value: Money, numberOfItems: number) => ({
    fractionDigits: value.fractionDigits,
    centAmount: value.centAmount * numberOfItems,
    currencyCode: value.currencyCode,
  })
}
