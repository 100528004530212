import { getProductAttribute, getProductAttributes } from 'composable/helpers/utils/product-card-utils'
import { parseAttributeArrayIntoString } from 'composable/helpers/utils/string-utils'

const BACKORDER_KEY = 'Awaiting Stock on'

export const cardOptions = {
  3: {
    sm: {
      imageSize: {
        width: '145px',
        height: '193px',
      },
      details: { props: { order: 3, marginTop: 1 } },
      quantity: { type: 'text', label: false, props: { order: 4, marginTop: 3 } },
      itemPrice: { type: 'text', label: true, props: { order: 2 } },
      contentColumn: {
        paddingTop: 4,
        paddingRight: 4,
      },
      remove: { type: 'text' },
    },
    lg: {
      imageSize: {
        width: '145px',
        height: '193px',
      },
      details: {
        props: {
          order: 3,
          marginTop: 3,
        },
      },
      quantity: { type: 'column', label: true, props: { order: 4, marginTop: 3 } },
      itemPrice: { type: 'text', label: true, props: { order: 2 } },
      contentColumn: {
        paddingTop: 4,
      },
      remove: { type: 'text' },
    },
  },
  4: {
    sm: {
      imageSize: {
        width: '80px',
        height: '106px',
      },
      details: { props: { order: 2, marginTop: 1 } },
      quantity: { type: 'text', label: false, props: { order: 3, marginTop: 3, marginBottom: 2 } },
      itemPrice: { type: 'text', label: true, props: { position: 'absolute', bottom: 10, right: 4 } },
      contentColumn: {
        paddingTop: 4,
      },
      remove: { type: 'text' },
    },
    lg: {
      imageSize: {
        width: '145px',
        height: '193px',
      },
      details: {
        props: {
          order: 2,
          marginTop: 1,
        },
      },
      quantity: { type: 'column', label: true, props: { order: 0, marginRight: 6, gap: 2, alignItems: 'end' } },
      itemPrice: { type: 'column', label: true, props: { order: 1, alignItems: 'end', marginRight: 4, gap: 3 } },
      contentColumn: {
        paddingTop: 4,
      },
      remove: { type: 'text' },
    },
  },
  5: {
    sm: {
      imageSize: {
        width: '80px',
        height: '106px',
      },
      details: { props: { order: 2, marginTop: 1 } },
      quantity: { type: 'text', label: false, props: { order: 3, marginTop: 3, marginBottom: 2 } },
      itemPrice: { type: 'text', label: true, props: { position: 'absolute', bottom: 10, right: 4 } },
      contentColumn: {
        paddingTop: 4,
      },
      remove: { type: 'text' },
    },
    lg: {
      imageSize: {
        width: '145px',
        height: '193px',
      },
      details: {
        props: {
          order: 2,
          marginTop: 1,
        },
      },
      quantity: { type: 'column', label: true, props: { order: 0, marginRight: 6, gap: 2, alignItems: 'end' } },
      itemPrice: { type: 'column', label: true, props: { order: 1, alignItems: 'end', marginRight: 6, gap: 3 } },
      itemtotalPrice: { type: 'column', label: true, props: { order: 2, alignItems: 'end', marginRight: 4, gap: 3 } },
      contentColumn: {
        paddingTop: 4,
      },
      remove: { type: 'text' },
    },
  },
}

export const readOnlyCartOptions = {
  2: {
    sm: {
      imageSize: {
        width: '80px',
        height: '106px',
      },
      quantity: { type: 'text', label: true },
      itemPrice: { type: 'text', label: true },
      contentColumn: {
        paddingTop: 0,
        paddingRight: 4,
      },
    },
    lg: {
      imageSize: {
        width: '100px',
        height: '133px',
      },
      quantity: { type: 'text', label: true },
      itemPrice: { type: 'text', label: true },
      contentColumn: {
        paddingTop: 0,
      },
    },
  },
  3: {
    sm: {
      imageSize: {
        width: '88px',
        height: '117px',
      },
      quantity: { type: 'text', label: true },
      itemPrice: { type: 'text', label: true },
      contentColumn: {
        paddingTop: 2,
      },
    },
    lg: {
      imageSize: {
        width: '145px',
        height: '193px',
      },
      quantity: { type: 'text', label: true },
      itemPrice: { type: 'column', label: true },
      contentColumn: {
        paddingTop: 4,
      },
    },
  },
  4: {
    sm: {
      imageSize: {
        width: '88px',
        height: '117px',
      },
      quantity: { type: 'text', label: true },
      itemPrice: { type: 'column', label: false },
      contentColumn: {
        paddingTop: 2,
      },
    },
    lg: {
      imageSize: {
        width: '145px',
        height: '193px',
      },
      quantity: { type: 'column', label: true },
      itemPrice: { type: 'column', label: true },
      contentColumn: {
        paddingTop: 4,
      },
    },
  },
}

export const cartlabels = (formatMessage) => {
  return {
    quantity: formatMessage({
      id: 'cart.item.quantity',
    }),
    itemPrice: formatMessage({
      id: 'cart.item.price',
    }),
    totalPrice: formatMessage({
      id: 'cart.item.totalPrice',
    }),
    apply: formatMessage({
      id: 'action.apply',
    }),
    remove: formatMessage({ id: 'action.remove' }),
    removeFromBag: formatMessage({ id: 'action.removeFromBag' }),
    addToWishlist: formatMessage({
      id: 'action.addToWishlist',
    }),
    alreadyAddedToWishList: formatMessage({
      id: 'action.addToWishlistTextAdded',
    }),
    outOfStock: formatMessage({
      id: 'cart.item.outOfStock',
    }),
    backOrder: formatMessage({
      id: 'cart.item.outOfStock',
    }),
  }
}

export const cartOptionValues = ({ columns, size, readOnly = false }) => {
  const imageSize = readOnly ? readOnlyCartOptions[columns][size].imageSize : cardOptions[columns][size].imageSize
  const quantityOptions = readOnly ? readOnlyCartOptions[columns][size].quantity : cardOptions[columns][size].quantity
  const itemPriceOptions = readOnly
    ? readOnlyCartOptions[columns][size].itemPrice
    : cardOptions[columns][size].itemPrice
  const itemtotalPriceOptions = readOnly
    ? readOnlyCartOptions[columns][size].itemtotalPrice
    : cardOptions[columns][size].itemtotalPrice
  const detailsOptions = readOnly ? readOnlyCartOptions[columns][size].details : cardOptions[columns][size].details
  const removeOptions = readOnly ? readOnlyCartOptions[columns][size].remove : cardOptions[columns][size].remove
  const contentColumn = readOnly
    ? readOnlyCartOptions[columns][size]?.contentColumn
    : cardOptions[columns][size]?.contentColumn
  return {
    imageSize,
    quantityOptions,
    itemPriceOptions,
    itemtotalPriceOptions,
    detailsOptions,
    removeOptions,
    contentColumn,
  }
}

export const fetchAttributeData = ({ image, masterProductData, variant, removedSegment3LabelWording = false }) => {
  const { src: imageSrc, alt: imageAlt = name } = image ?? {}
  const segment2Label = masterProductData?.find(({ name }) => name === 'segment2Label')?.value
  const segment3Label = masterProductData?.find(({ name }) => name === 'segment3Label')?.value
  const productAttributes = getProductAttributes(variant, segment2Label, segment3Label) ?? []
  const parsedProductAttributes = parseAttributeArrayIntoString(productAttributes, removedSegment3LabelWording) ?? ''
  const productItemNumber = getProductAttribute({ variant, attribute: 'itemNumber' })
  const brand = masterProductData?.find(({ name }) => name === 'brand')?.value
  return {
    imageSrc,
    imageAlt,
    parsedProductAttributes,
    productItemNumber,
    brand,
  }
}

export const evaluateBackorder = (availabilityStatus: string) => {
  const regex = /\b\d{2}\/\d{2}\/\d{4}\b/
  const isBackordered = availabilityStatus?.includes(BACKORDER_KEY) ?? false
  const date = availabilityStatus?.match(regex)
  const availabilityDate = date?.length > 0 ? date[0] : ''

  return {
    isBackordered,
    availabilityDate,
  }
}
