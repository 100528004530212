import { HorizontalProductCardEditable } from './horizontal-product-card-editable'
import { HorizontalProductCardProps } from './types'

// TODO: make it work for 3 scenarios like in STS.
// This component handles 3 scenarios -
// - If the product is inStock and allows the user to change the cart items
// - If the product is inStock and does not allow the user to change the cart
// - If the product is not inStock or if the product does not have enough qty
// Refer to this for designs - https://www.figma.com/design/8mSERuPQvxs8pUkitUpMHR/DXL.com?node-id=4942-492270&t=XsahsxxKkNVGboQp-0

export const HorizontalProductCard = (props: HorizontalProductCardProps) => {
  return <HorizontalProductCardEditable {...props} />
}
