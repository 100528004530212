import React from 'react'
import { Box, Text, Flex, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'

export interface OptionsGetProductProps {
  locationStore: string
  orderByTime: string
  valueSelection: string
  setSelection?: React.Dispatch<React.SetStateAction<string>>
  isDisabled: boolean
}

export const OptionsGetProduct = ({
  locationStore,
  orderByTime,
  valueSelection,
  setSelection,
  isDisabled,
}: OptionsGetProductProps) => {
  const { formatMessage } = useFormat({ name: 'common' })

  return (
    <RadioGroup onChange={setSelection} value={valueSelection} marginTop={'var(--chakra-space-2)'}>
      <Stack direction="column">
        <Box>
          <Radio value="shipping" isDisabled={isDisabled}>
            <Text fontSize={'14px'}>{formatMessage({ id: 'cart.options.shipping' })}</Text>
          </Radio>
        </Box>
        <Box>
          <Flex align="flex-start">
            <Radio value="pickup" marginTop="2px" isDisabled={isDisabled} />
            <Box ml={2}>
              <Text fontSize={'14px'} color={isDisabled ? 'text.muted' : ''}>
                {formatMessage({ id: 'cart.options.store.pickup', values: { locationStore: locationStore } })}
              </Text>
              <Text fontSize={'14px'} color="text.muted">
                {formatMessage({ id: 'cart.options.store.orderBy', values: { orderByTime: orderByTime } })}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Stack>
    </RadioGroup>
  )
}
