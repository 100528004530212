import { Variant } from '@Types/product/Variant'

export function getProductAttributes(variant: Variant, segment2Label?: string, segment3Label?: string) {
  const details = Object.entries(variant.attributes)
    .filter(([attr, value]) => ['ecommColor', 'segment2', 'segment3'].includes(attr))
    .map(([attr, value]) => {
      if (attr === 'ecommColor') {
        return { name: 'color', value }
      }
      if (attr === 'segment2') {
        return { name: segment2Label, value }
      }
      if (attr === 'segment3') {
        return { name: segment3Label, value }
      }
    })

  return details
}

export function getProductAttribute({ variant, attribute }: { variant: Variant; attribute: string }) {
  const brand = Object.entries(variant?.attributes).find(([attr, _value]) => attr === attribute)?.[1] as string
  return brand
}

export function getAttributeFromMasterVariant({
  masterProductData,
  attribute,
}: {
  masterProductData: Array<{ name: string; value: string }>
  attribute: string
}) {
  const brand = masterProductData.find((attr) => attr.name === attribute)?.value
  return brand
}

export const deduplicateAndSort = (prices: (number | string)[]): number[] => {
  return [...new Set(prices)]
    .map((price) => (typeof price === 'number' ? price : parseFloat(price)))
    .filter((price) => !isNaN(price))
    .sort((a, b) => a - b)
}

export const limitToMinMax = (prices) => {
  if (prices.length > 2) {
    return [Math.min(...prices), Math.max(...prices)]
  }
  return prices
}

export const calculateFinalDiscountPercentage = (
  finalOriginalPrice: number[],
  finalSellingPrice: number[],
  discountAmount?: number,
): number => {
  const highestDiscounted =
    ((finalOriginalPrice[finalOriginalPrice.length - 1] - finalSellingPrice[0]) /
      finalOriginalPrice[finalOriginalPrice.length - 1]) *
    100

  return highestDiscounted > (discountAmount ?? 0) ? highestDiscounted : (discountAmount ?? highestDiscounted)
}
